import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';
import AutoNbsp from '@components/utilities/AutoNbsp';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import { useGenerateRoute } from '@libs/localizedRoute';
import companySearchImage from 'public/images/homepageYouCanDo/companySearch.jpg';
import jobDescriptionImage from 'public/images/homepageYouCanDo/jobDescriptions.jpg';
import reviewImage from 'public/images/homepageYouCanDo/review.jpeg';
import classes from './HomepageYouCanDo.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HomepageYouCanDo = ({ className, ...divProps }: Props) => {
  const { isMobile } = useActiveBreakpoint();
  const { t } = useTranslation('homepage');
  const generateRoute = useGenerateRoute();

  const homepageYouCanDoItems = [
    {
      image: companySearchImage,
      title: t('HOMEPAGE_YOU_CAN_DO_JOB_OPPORTUNITIES_TITLE'),
      text: t('HOMEPAGE_YOU_CAN_DO_JOB_OPPORTUNITIES_DESCRIPTION'),
      button: t('HOMEPAGE_YOU_CAN_DO_JOB_OPPORTUNITIES_BUTTON'),
      buttonUrl: generateRoute('front.job_description.list'),
    },
    {
      image: jobDescriptionImage,
      title: t('HOMEPAGE_YOU_CAN_DO_COMPANY_LIST_TITLE'),
      text: t('HOMEPAGE_YOU_CAN_DO_COMPANY_LIST_DESCRIPTION'),
      button: t('HOMEPAGE_YOU_CAN_DO_COMPANY_LIST_BUTTON'),
      buttonUrl: generateRoute('companySearch.search'),
    },
    {
      image: reviewImage,
      title: t('HOMEPAGE_YOU_CAN_DO_REVIEW_TITLE'),
      text: t('HOMEPAGE_YOU_CAN_DO_REVIEW_DESCRIPTION'),
      button: t('HOMEPAGE_YOU_CAN_DO_REVIEW_BUTTON'),
      buttonUrl: generateRoute('front.questionnaire.basic'),
    },
  ];

  return (
    <div className={className} {...divProps}>
      <h2 className={classes.HomepageYouCanDo__heading}>{t('HOMEPAGE_YOU_CAN_DO_TITLE')}</h2>
      {homepageYouCanDoItems.map((youCanDoItem) => (
        <div className={classes.HomepageYouCanDo__item} key={`HomepageYouCanDo_${youCanDoItem.title}`}>
          <div className={classes.HomepageYouCanDo__imageContainer}>
            <Image
              src={youCanDoItem.image.src}
              alt={`Image_${youCanDoItem.title.toLowerCase().replace(/\s/g, '_')}`}
              fill
              className={classes.HomepageYouCanDo__image}
            />
          </div>
          <div className={classes.HomepageYouCanDo__itemBody}>
            <h3>{youCanDoItem.title}</h3>
            <p>
              <AutoNbsp>{youCanDoItem.text}</AutoNbsp>
            </p>
            <NextButtonLink href={youCanDoItem.buttonUrl} isBlock={isMobile}>
              {youCanDoItem.button}
            </NextButtonLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomepageYouCanDo;

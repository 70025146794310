import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import Tick from '@icons/tick.svg';
import { useGenerateRoute } from '@libs/localizedRoute';
import classes from './HomepageQuestionnaireSection.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HomepageQuestionnaireSection = ({ className, ...divProps }: Props) => {
  const generateRoute = useGenerateRoute();
  const { t, i18n } = useTranslation(['homepage']);
  const illustrationReviewsLargeTextSrcFallback = `/images/homepageQuestionnaireSection/cs/illustration-reviews-large-text.png`;
  const illustrationReviewsSrcFallback = `/images/homepageQuestionnaireSection/cs/illustration-reviews.png`;
  const [illustrationReviewsLargeTextSrc, setIllustrationReviewsLargeTextSrc] = useState(
    `/images/homepageQuestionnaireSection/${i18n.language}/illustration-reviews-large-text.png`,
  );
  const [illustrationReviewsSrc, setIllustrationReviewsSrc] = useState(
    `/images/homepageQuestionnaireSection/${i18n.language}/illustration-reviews.png`,
  );

  return (
    <div className={classNames(classes.HomepageQuestionnaireSection, className)} {...divProps}>
      <div className={classes.HomepageQuestionnaireSection__gradient}></div>
      <div className={classes.HomepageQuestionnaireSection__content}>
        <h2>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TITLE')}</h2>
        <div className={'mt-400'}>
          <div className={classes.HomepageQuestionnaireSection__contentSubtitle}>
            {t('HOMEPAGE_QUESTIONNAIRE_SECTION_SUBTITLE')}
          </div>
          <NextButtonLink href={generateRoute('front.questionnaire.basic')} color="primary" className="my-700">
            {t('HOMEPAGE_QUESTIONNAIRE_SECTION_ADD_REVIEW_BUTTON')}
          </NextButtonLink>
          <div>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_DESCRIPTION')}</div>
        </div>
        <div className={classes.HomepageQuestionnaireSection__imageWrapper}>
          <div className={classes.HomepageQuestionnaireSection__defaultImage}>
            <Image
              src={illustrationReviewsSrc}
              width={1360}
              height={780}
              alt="Add review illustration"
              onLoadingComplete={(result) => {
                if (result.naturalWidth === 0) {
                  setIllustrationReviewsSrc(illustrationReviewsSrcFallback);
                }
              }}
              onError={() => {
                setIllustrationReviewsSrc(illustrationReviewsSrcFallback);
              }}
              className={classes.HomepageQuestionnaireSection__image}
            />
          </div>
          <div className={classes.HomepageQuestionnaireSection__imageWithLargerText}>
            <Image
              src={illustrationReviewsLargeTextSrc}
              width={680}
              height={390}
              alt="Add review illustration"
              onLoadingComplete={(result) => {
                if (result.naturalWidth === 0) {
                  setIllustrationReviewsLargeTextSrc(illustrationReviewsLargeTextSrcFallback);
                }
              }}
              onError={() => {
                setIllustrationReviewsLargeTextSrc(illustrationReviewsLargeTextSrcFallback);
              }}
              className={classes.HomepageQuestionnaireSection__image}
            />
          </div>
        </div>
        <div className={classes.HomepageQuestionnaireSection__tips}>
          <div className={classes.HomepageQuestionnaireSection__tip}>
            <div>
              <Tick />
            </div>
            <div className={classes.HomepageQuestionnaireSection__tipContent}>
              <strong>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_1_TITLE')}</strong>
              <p>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_1_CONTENT')}</p>
            </div>
          </div>
          <div className={classes.HomepageQuestionnaireSection__tip}>
            <div>
              <Tick />
            </div>
            <div className={classes.HomepageQuestionnaireSection__tipContent}>
              <strong>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_2_TITLE')}</strong>
              <p>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_2_CONTENT')}</p>
            </div>
          </div>
          <div className={classes.HomepageQuestionnaireSection__tip}>
            <div>
              <Tick />
            </div>
            <div className={classes.HomepageQuestionnaireSection__tipContent}>
              <strong>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_3_TITLE')}</strong>
              <p>{t('HOMEPAGE_QUESTIONNAIRE_SECTION_TIP_3_CONTENT')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageQuestionnaireSection;

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import AutoNbsp from '@components/utilities/AutoNbsp';
import classes from './HomepageSuitableCard.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  imageUrl: string;
  title: string;
  content: string;
  suitableProfilesCount: number;
  link: string;
}

const HomepageSuitableCard = ({
  imageUrl,
  title,
  content,
  suitableProfilesCount,
  link,
  className,
  ...divProps
}: Props) => {
  const { t } = useTranslation(['front']);

  return (
    <div className={classNames(classes.HomepageSuitableCard, className)} {...divProps}>
      <a href={link} className={classes.HomepageSuitableCard__overlayWrapper}>
        <Image className={classes.HomepageSuitableCard__image} src={imageUrl} alt={`Image_${title}`} fill />
        <h2 className={classes.HomepageSuitableCard__heading}>
          <AutoNbsp extraWordsFollowedByNbsp={['ne']}>{title}</AutoNbsp>
        </h2>
      </a>
      <div className={classes.HomepageSuitableCard__body}>
        <p>
          <AutoNbsp>{content}</AutoNbsp>
        </p>
        <Link href={link}>{t('COUNT_OF_COMPANIES', { count: suitableProfilesCount })}</Link>
      </div>
    </div>
  );
};

export default HomepageSuitableCard;
